import { useEffect, useReducer, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import DatePicker from "components/date-picker";
import Button from "components/button";
import Input from "components/input";
import Select from "components/select";
import Pagination from "components/pagination";
import TransactionModal from "./components/transaction-modal";
import { fetchBankList } from "reducers/bank/bankThunk";
import { reducer, initialState } from "./reducer";
import { fetchHistoryRequests } from "reducers/limits/limitsThunk";
import { cnpjMask, cpfMask, numberMask } from "utils/mask";
import { renderDocument, formatDate } from "utils/mask";
import {
  SET_DATE_FILTER,
  SET_CURRENT_PAGE,
  SET_ITEMS_PER_PAGE,
  SET_ID_CUSTOMER_BANK,
} from "./reducer";

const LimitHistoryList = () => {
  const dispatch = useDispatch();

  const { historyList } = useSelector((store) => store.limits);
  const { banks } = useSelector((store) => store.bank);
  const [state, localDispatch] = useReducer(reducer, initialState);
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [bankList, setBankList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState(null);

  const fetchHistoryRequestsHandler = useCallback(() => {
    dispatch(
      fetchHistoryRequests({
        dataInicio: state.dateFilter.startDate,
        dataFim: state.dateFilter.endDate,
        paginaAtual: state.currentPage,
        documento: numberMask(document),
        itemsPerPage: state.itemsPerPage,
        idCustomerBank:
          state.idCustomerBank === "0" ? null : state.idCustomerBank,
      })
    );

    // eslint-disable-next-line
  }, [dispatch, state, document]);

  useEffect(() => {
    fetchHistoryRequestsHandler();

    // eslint-disable-next-line
  }, [state.dateFilter, state.currentPage, state.itemsPerPage]);

  useEffect(() => {
    if (!banks.length) dispatch(fetchBankList());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const bankListArray = banks.map((bank) => ({
      id: bank.idBanco,
      name: bank.descricao,
    }));

    bankListArray.unshift({ id: 0, name: "Todos os bancos" });

    setBankList(bankListArray);
  }, [banks]);

  const handleChangeDate = (newValue) => {
    const startDate = moment(newValue.startDate, "YYYY-MM-DD");
    const endDate = moment(newValue.endDate, "YYYY-MM-DD");

    localDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleNextPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    localDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const handleChange = (evt) => {
    const { value } = evt.target;

    if (!value.length) {
      setDocument("");
    } else if (numberMask(value).length <= 11) {
      setDocument(cpfMask(value));
    } else {
      setDocument(cnpjMask(value));
    }
  };

  const isValidDocument = () => {
    if (document.length) {
      if (
        numberMask(document).length !== 11 &&
        numberMask(document).length !== 14
      ) {
        setError("Tamanho inválido");
        return false;
      }
    }

    return true;
  };

  const handleFilter = () => {
    if (!isValidDocument()) return;

    setError("");

    if (state.currentPage !== 1) {
      changeCurrentPage();
    } else {
      fetchHistoryRequestsHandler();
    }
  };

  const changeBankHandler = (event) => {
    const { value } = event.target;

    localDispatch({
      type: SET_ID_CUSTOMER_BANK,
      payload: value,
    });
  };

  const handleOnEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleFilter();
    }
  };

  const handleNavigation = (id, document) => {
    let type = 1;
    document.length === 14 ? (type = 2) : (type = 1);

    return `/contas/detalhes/${id}?type=${type}`;
  };

  const openModalHandler = (item) => {
    setItem(item);
    setIsModalOpen(true);
  };

  return (
    <div className="relative">
      <TransactionModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        item={item}
        setItem={setItem}
      />
      <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-4 lg:flex">
        <div className="rounded-md w-full col-span-1 lg:max-w-xs">
          <DatePicker
            dateFilter={state.dateFilter}
            handleChangeDate={handleChangeDate}
            className={"sm:w-full sm:max-w-none lg:max-w-xs"}
          />
        </div>
        {process.env.REACT_APP_MODE === "CORE" && (
          <Select
            className={"mt-0 w-full col-span-1 lg:max-w-xs"}
            id={"idCustomerBank"}
            name={"idCustomerBank"}
            value={state.idCustomerBank || ""}
            placeholder={"Selecione o banco desejado"}
            options={bankList}
            onChange={changeBankHandler}
          />
        )}
        <div className="w-full col-span-1 lg:max-w-xs">
          <Input
            type={"text"}
            name={"document"}
            id={"document"}
            placeholder={"Informe o CPF ou CNPJ"}
            onChange={handleChange}
            value={document}
            error={error}
            errorMessage={error}
            onKeyUp={handleOnEnter}
          />
        </div>
        <Button
          type={"submit"}
          label={"Filtrar"}
          onClick={handleFilter}
          className={"w-full col-span-1 lg:max-w-xs"}
        />
      </div>
      <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                ID Cliente
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell"
              >
                Cliente
              </th>
              {process.env.REACT_APP_MODE === "CORE" && (
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell"
                >
                  Banco
                </th>
              )}
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Data / Hora resposta
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Transação
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Tipo de limite
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Detalhes</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {historyList?.itens?.length ? (
              historyList.itens.map((item) => (
                <tr key={item.id}>
                  <td className="pl-4 pr-3 py-4 sm:pl-6 text-sm text-gray-500 whitespace-nowrap">
                    <Link
                      className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                      to={handleNavigation(item.idClient, item.documento)}
                      target="_blank"
                    >
                      {item.idClient}
                    </Link>
                    <dl className="font-normal">
                      <dt className="sr-only xl:hidden">Cliente</dt>
                      <dd className="mt-1 text-gray-500 flex flex-col xl:hidden">
                        <span className="font-medium text-gray-900">
                          {item.nomeCliente}
                        </span>
                        <span>{renderDocument(item.documento)}</span>
                      </dd>
                      <dt className="sr-only xl:hidden">Nome banco</dt>
                      <dd className="mt-1 truncate text-gray-500 xl:hidden">
                        {item.nomeBanco}
                      </dd>
                      <dt className="sr-only lg:hidden">Data atualização</dt>
                      <dd className="mt-1 truncate text-gray-500 lg:hidden">
                        Atualizado em: {formatDate(item.dataAtualizacao)}
                      </dd>
                      <dt className="sr-only lg:hidden">Transação</dt>
                      <dd className="mt-1 truncate text-gray-500 lg:hidden">
                        {item.transacao}
                      </dd>
                      <dt className="sr-only md:hidden">Tipo de limite</dt>
                      <dd className="mt-1 truncate text-gray-500 md:hidden">
                        Limite {item.limiteNoturno ? "noturno" : "diário"}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 xl:table-cell">
                    <div className="flex flex-col">
                      <span className="font-medium text-gray-900">
                        {item.nomeCliente}
                      </span>
                      <span>{renderDocument(item.documento)}</span>
                    </div>
                  </td>
                  {process.env.REACT_APP_MODE === "CORE" && (
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">
                      {item.nomeBanco}
                    </td>
                  )}
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {formatDate(item.dataAtualizacao)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {item.transacao}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {item.limiteNoturno ? "Noturno" : "Diário"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <button
                      className="text-indigo-600 hover:text-indigo-900 text-left font-medium"
                      onClick={() => openModalHandler(item)}
                    >
                      Detalhes
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={historyList.total}
          pageItens={historyList?.itens?.length}
          pageCount={Math.ceil(historyList.total / state.itemsPerPage)}
          currentPage={state.currentPage}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          onClick={changeCurrentPage}
          itemsPerPage={state.itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPageHandler}
        />
      </div>
    </div>
  );
};

export default LimitHistoryList;
