import React from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";

import { setAzureAccount } from "reducers/azure-account/azureAccountSlice";
import { loginRequest } from "services/authConfig";
import { banks } from "utils/banks";

export default function Login() {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  function handleLogin() {
    instance
      .loginPopup({ ...loginRequest, prompt: "select_account" })
      .then((res) => {
        dispatch(
          setAzureAccount({
            homeAccountId: res.account.homeAccountId,
            localAccountId: res.account.localAccountId,
            username: res.account.username,
          })
        );
        localStorage.setItem("homeAccountId", res.account.homeAccountId);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function getBankLogo() {
    const idCustomerBank = parseInt(process.env.REACT_APP_ID_BANK) || 1;

    const bank = banks.find((item) => item.idCustomerBank === idCustomerBank);
    return bank.svg;
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="max-w-[200px] h-12 mx-auto flex justify-center">
              {getBankLogo()}
            </div>

            <div className="flex flex-col justify-center items-center my-8 gap-2">
              <h1 className="text-center text-3xl font-semibold text-gray-900">
                Olá
              </h1>
              <p className="text-center text-sm font-medium text-gray-600">
                Clique abaixo e faça o login para acessar o dashboard!
              </p>
            </div>
            <button
              onClick={handleLogin}
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Entrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
