import {
  IdentificationIcon,
  QueueListIcon,
  DocumentMagnifyingGlassIcon,
  ShieldExclamationIcon,
  BanknotesIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

import {
  USER_FINANCEIRO,
  USER_ADMIN,
  USER_OPERACOES,
  ADMIN_ECOSYSTEM,
} from "utils/const";

const navigationMenu = [
  {
    id: 6,
    name: "Início",
    href: "/",
    describe: "",
    current: true,
    protected: true,
    roles: [ADMIN_ECOSYSTEM],
    available: true,
    icon: <HomeIcon className="h-5 w-5" />,
    subMenu: [],
  },
  {
    id: 1,
    name: "Contas",
    href: "/contas",
    current: true,
    protected: false,
    roles: [],
    available: true,
    icon: <IdentificationIcon className="h-5 w-5" />,
    subMenu: [
      {
        id: 1,
        name: "Contas abertas",
        href: "/contas",
        protected: false,
        roles: [],
        available: true,
      },
      {
        id: 2,
        name: "Abrir nova conta",
        href: "/contas/abertura",
        protected: true,
        roles: [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM],
        available: true,
      },
      {
        id: 3,
        name: "Solicitação de cancelamento",
        href: "/contas/cancelamento",
        protected: true,
        roles: [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM],
        available: true,
      },
      {
        id: 4,
        name: "Aumento de limite",
        href: "/contas/limites",
        protected: true,
        roles: [USER_ADMIN, ADMIN_ECOSYSTEM],
        available: true,
      },
    ],
  },
  {
    id: 3,
    name: "Abertura de contas",
    href: "/onboarding",
    describe: "",
    current: true,
    protected: false,
    roles: [],
    available: true,
    icon: <QueueListIcon className="h-5 w-5" />,
    subMenu: [
      {
        id: 2,
        name: "Lista Permissiva",
        href: "/onboarding/permitir",
        protected: false,
        roles: [],
        available: true,
      },
      {
        id: 3,
        name: "Lista Restritiva",
        href: "/onboarding/restringir",
        protected: false,
        roles: [],
        available: true,
      },
    ],
  },
  {
    id: 2,
    name: "Financeiro",
    href: "/financeiro",
    describe: "",
    current: false,
    protected: true,
    roles: [USER_ADMIN, USER_FINANCEIRO, ADMIN_ECOSYSTEM],
    available: true,
    icon: <BanknotesIcon className="h-5 w-5" />,
    subMenu: [
      {
        id: 1,
        name: "Conciliação",
        href: "/financeiro/conciliacao",
        protected: false,
        roles: [],
        available: true,
      },
      {
        id: 2,
        name: "Tarifas",
        href: "/financeiro/tarifas",
        protected: true,
        roles: [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM],
        available: true,
      },
    ],
  },
  {
    id: 4,
    name: "Erros",
    href: "/erros",
    describe: "",
    current: false,
    protected: true,
    roles: [USER_ADMIN],
    available: process.env.REACT_APP_MODE === "CORE" ? true : false,
    icon: <ShieldExclamationIcon className="h-5 w-5" />,
    subMenu: [],
  },
  {
    id: 5,
    name: "Logs",
    href: "/logs",
    describe: "",
    current: false,
    protected: true,
    roles: [USER_ADMIN],
    available: process.env.REACT_APP_MODE === "CORE" ? true : false,
    icon: <DocumentMagnifyingGlassIcon className="h-5 w-5" />,
    subMenu: [],
  },
];

export default navigationMenu;
