import { useSelector } from "react-redux";
import { BuildingLibraryIcon } from "@heroicons/react/24/outline";

import { getUTCTime } from "utils/mask";
import { logos } from "utils/logos";

const getBankLogo = (idBank) => {
  const logo = logos.filter((logo) => logo.idCustomerBank === idBank);

  if (logo.length) {
    return (
      <div className="rounded-md">
        <div className="h-9 w-9 flex items-center">{logo[0].svg}</div>
      </div>
    );
  } else {
    return (
      <div className="rounded-md bg-indigo-500 p-3">
        <BuildingLibraryIcon
          className="h-4 w-4 text-white"
          aria-hidden="true"
        />
      </div>
    );
  }
};

const NightTime = () => {
  const { banks } = useSelector((store) => store.bank);

  return (
    <div className="relative">
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="bg-white ">
          <div className="py-6 px-4 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Horário noturno
            </h3>
          </div>
          <div>
            <ul className="divide-y divide-gray-200">
              {banks.map((bank) => (
                <li key={bank.idBanco}>
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      {getBankLogo(bank.idBanco)}
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                        <div className="flex  flex-col justify-center">
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {bank.descricao}
                          </p>
                          <div className="md:hidden space-y-0.5 mt-2">
                            <p className="text-sm text-gray-900">
                              Horário inicial{" "}
                              <span className="text-gray-500">
                                {getUTCTime(bank.horarioInicioNoturno)}
                              </span>
                            </p>
                            <p className="text-sm text-gray-900">
                              Horário final{" "}
                              <span className="text-gray-500">
                                {getUTCTime(bank.horarioFinalNoturno)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <div>
                            <p className="text-sm text-gray-900">
                              Horário inicial
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              {getUTCTime(bank.horarioInicioNoturno)}
                            </p>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <div>
                            <p className="text-sm text-gray-900">
                              Horário final
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              {getUTCTime(bank.horarioFinalNoturno)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NightTime;
