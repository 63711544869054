import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import { cn } from "utils/classNames";
import { convertCurrencyBRLToString } from "utils/mask";

const ValuesData = () => {
  const { metrics } = useSelector((store) => store.metrics);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (metrics.length > 0) {
      const valuesArray = [
        {
          name: "Saldo atual",
          value: metrics[0]?.saldo,
          color: "text-indigo-600",
          tooltip: "Saldo total em contas do ecossistema.",
        },
        // {
        //   name: "Tarifas internas",
        //   value: metrics[0]?.tarifas,
        //   color: "text-red-500",
        //   tooltip:
        //     "Valor total a pagar em tarifas para a BaaS2u, por transações realizadas no ecossistema.",
        // },
        {
          name: "Total cobrado em tarifas",
          value: metrics[0]?.tarifas,
          color: "text-success",
          tooltip:
            "Soma dos valores cobrados em tarifas para os clientes (correntistas).",
        },
        {
          name: "Limite total disponível PIX",
          value: metrics[0]?.limitePix,
          color: "text-indigo-600",
          tooltip:
            "Total do limite transacional para operações de Pix disponível para todo o ecossistema do banco.",
        },
      ];

      setValues(valuesArray);
    }
  }, [metrics]);

  return (
    <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-1 lg:grid-cols-3 border rounded-lg overflow-hidden">
      {values.map((value) => (
        <div
          key={value.name}
          className="flex flex-col flex-wrap items-center justify-center gap-x-4 gap-y-2 bg-white p-6"
        >
          <dd
            className={cn(
              "text-3xl font-medium leading-10 tracking-tight",
              value?.color
            )}
          >
            R$ {convertCurrencyBRLToString(value.value)}
          </dd>
          <dt className="text-sm font-medium leading-6 text-gray-500 uppercase flex flex-row items-center gap-1">
            {value.name}
            <QuestionMarkCircleIcon
              className="h-5 w-5 cursor-help"
              data-tooltip-id={value.name}
            />
            <Tooltip id={value.name}>
              <div className="text-center text-sm normal-case">
                {value.tooltip}
              </div>
            </Tooltip>
          </dt>
        </div>
      ))}
    </dl>
  );
};

export default ValuesData;
